import { useState, useEffect, useRef, forwardRef, useImperativeHandle, Fragment } from 'react';
//import { isMobile } from 'react-device-detect';
import css from '../styles/modules/Select.module.scss';

const Select = forwardRef((params, ref) => {
  const { title, placeholder, options, selected, multiple, search, visibility, style, onChange, onClose } = params;

  // console.log(options);
  // console.log(selected);
  // console.log(visibility);

  const isMobile = false;

  /** STATES */
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(Array.isArray(selected) ? selected.map((v) => v.toString()) : [selected.toString()]);
  const [labels, setLabels] = useState(() => {
  const finalArray = [];
  

    // eslint-disable-next-line no-restricted-syntax
    for (const item of options) {
      if (item.type === 'optgroup') {
        // eslint-disable-next-line no-restricted-syntax
        for (const it of item.options) {
          if (values.includes(it.value)) finalArray.push(it.text);
        }
      } else if (values.includes(item.value)) finalArray.push(item.text);
    }

    return finalArray;
  });
  const [all, setAll] = useState(() => {
    const allOptions = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const item of options) {
      if (item.type === 'optgroup') {
        // eslint-disable-next-line no-restricted-syntax
        for (const it of item.options) {
          allOptions[it.value.toString()] = { text: it.text, value: it.value };
        }
      } else allOptions[item.value.toString()] = { text: item.text, value: item.value };
    }

    return allOptions;
  });

  /** REFS */
  const parentEl = useRef();

  /** HANDLERS */
  const handleOutsideClick = (e) => {
    if (e.target.closest(`.${css.sl}`) !== parentEl.current) {
      setIsOpen(false);
      if (onClose)
        onClose();
    }
  };
  const handleOptionClick = (v, t) => {
    if (values.includes(v)) {
      setValues(multiple ? values.filter((a) => a !== v) : []);
      setLabels(multiple ? labels.filter((a) => a !== t) : []);

      if (onChange)
        onChange(multiple ? values.filter((a) => a !== v) : []);
    } else {
      setValues(multiple ? values.concat([v]) : [v]);
      setLabels(multiple ? labels.concat([t]) : [t]);

      if (onChange)
        onChange(multiple ? values.concat([v]) : [v]);

      if (!multiple) 
        setIsOpen(false);
    }
  };
  const handleGroupClick = (v, t) => {
    if (!multiple) return;

    /** If all items inside group already selected - deselect all. */
    if (v.every((ai) => values.includes(ai))) {
      setValues(values.filter((a) => !v.includes(a)));
      setLabels(labels.filter((a) => !t.includes(a)));

      if (onChange)
        onChange(values.filter((a) => !v.includes(a)));
    } else {
      setValues(values.concat(v.filter((a) => !values.includes(a))));
      setLabels(labels.concat(t.filter((a) => !labels.includes(a))));

      if (onChange)
        onChange(values.concat(v.filter((a) => !values.includes(a))));
    }
  };
  const handleOptionMobileClick = (e) => {
    const selectedValues = [...e.target.options].filter((x) => x.selected);

    setValues(selectedValues.map((x) => x.value));
    setLabels(selectedValues.map((x) => x.innerText));

    if (onChange)
      onChange(selectedValues.map((x) => x.value));
  };
  const handleSearchInput = (e) => {
    // eslint-disable-next-line no-unused-vars
    const inValue = e.target.value.toLowerCase();
    console.log(all);
  };
  const canDropdownDown = () => {
    const dropdownHeight = Object.keys(all).length * 50;
    const parentElPosition = parentEl.current.getBoundingClientRect();

    if (
      parentElPosition.top + parentElPosition.height + Math.min(dropdownHeight, 320) >
      window.innerHeight
    )
      return false;

    return true;
  };

  /** HOOKS */
  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => document.body.removeEventListener('click', handleOutsideClick);
  }, []);
  useEffect(() => {
    const newValues = Array.isArray(selected) ? selected.map((v) => v.toString()) : [selected.toString()];
    const labelsArray = [];
    const allOptions = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const item of options) {
      if (item.type === 'optgroup') {
        // eslint-disable-next-line no-restricted-syntax
        for (const it of item.options) {
          if (newValues.includes(it.value))
            labelsArray.push(it.text);

          allOptions[it.value.toString()] = it;
        }
      } else if (newValues.includes(item.value)) {
        labelsArray.push(item.text);
      } else {
        allOptions[item.value.toString()] = item;
      }
    }

    setValues(newValues);
    setLabels(labelsArray);
    setAll(allOptions);
  }, [selected, options]);
  useImperativeHandle(ref, () => ({
    get() {
      return values;
    },
    set(x) {
      const inValues = Array.isArray(x) ? x.map((v) => v.toString()) : [x.toString()];
      const availableOptions = Object.entries(all).filter((a) => inValues.includes(a[0]));

      setValues(availableOptions.map((v) => v[1].value));
      setLabels(availableOptions.map((v) => v[1].text));
    }
  }));

  /** TEMPLATE */
  if (!options.length || visibility === false)
    return null;

  return (
    <div className={css.sl} ref={parentEl} style={style}>
      {title && <div className={css.slTitle}>{title}</div>}
      <div className={css.slContent}>
        <div className={css.slView}>
          <button
            type="button"
            className={[css.slSelect, isOpen && css.active].filter(Boolean).join(' ')}
            onClick={() => {
              setIsOpen(!isOpen);
              if (onClose) onClose();
            }}
          >
            {isMobile && (
              <select
                className={css.slSelectHidden}
                multiple={multiple}
                value={values || []}
                onChange={handleOptionMobileClick}
              >
                {options.map((value, index) => {
                  if (value.type === 'optgroup') {
                    return (
                      <optgroup label={value.text} key={index}>
                        {value.options.map((v, i) => (
                          <option value={v.value} key={i}>
                            {v.text}
                          </option>
                        ))}
                      </optgroup>
                    );
                  }

                  if (value.type === 'option') {
                    return (
                      <option value={value.value} key={index}>
                        {value.text}
                      </option>
                    );
                  }

                  return null;
                })}
              </select>
            )}
            {placeholder && labels.length <= 0 ? (
              <div className={css.slSelectPlaceholder}>{placeholder}</div>
            ) : (
              <div className={css.slSelectLabels}>{labels.join(', ')}</div>
            )}
          </button>
          {!!values.length && (
            <button
              type="button"
              title="Очистить"
              className={css.slReset}
              onClick={() => {
                setValues([]);
                setLabels([]);

                if (onChange) onChange([]);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          )}
        </div>
        {isOpen && !isMobile && (
          <div
            className={css.slDropdown}
            style={!canDropdownDown() ? { top: 'auto', bottom: 'calc(100% + 2px)' } : null}
          >
            {/* TODO: Creating dynamic search from options. */}
            {search && (
              <div className={css.slDropdownSearch}>
                <input
                  placeholder="Начните вводить поисковой запрос..."
                  onInput={handleSearchInput}
                />
              </div>
            )}
            {options.map((value, index) => {
              if (value.type === 'optgroup') {
                const valuesInsideGroup = value.options.map((v) => v.value);
                const labelsInsideGroup = value.options.map((v) => v.text);
                const allValuesSelected = valuesInsideGroup.every((ai) => values.includes(ai));

                return (
                  <Fragment key={index}>
                    <button
                      type="button"
                      className={[
                        css.slDropdownItem,
                        allValuesSelected && css.active,
                        css.big,
                        !multiple && value.options.length > 1 && css.disabled
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      onClick={() => handleGroupClick(valuesInsideGroup, labelsInsideGroup)}
                    >
                      <div className={css.slDropdownItemCheckbox}>
                        <div>
                          {allValuesSelected && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          )}
                        </div>
                      </div>
                      <div className={css.slDropdownItemText}>{value.text}</div>
                    </button>
                    {value.options.map((v, i) => (
                      <button
                        type="button"
                        className={[
                          css.slDropdownItem,
                          css.group,
                          values.includes(v.value) && css.active
                        ]
                          .filter(Boolean)
                          .join(' ')}
                        onClick={() => handleOptionClick(v.value.toString(), v.text)}
                        key={i}
                      >
                        <div className={css.slDropdownItemCheckbox}>
                          <div>
                            {values.includes(v.value.toString()) && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            )}
                          </div>
                        </div>
                        <div className={css.slDropdownItemText}>{v.text}</div>
                      </button>
                    ))}
                  </Fragment>
                );
              }

              if (value.type === 'option')
                return (
                  <button
                    type="button"
                    className={[css.slDropdownItem, values.includes(value.value) && css.active]
                      .filter(Boolean)
                      .join(' ')}
                    onClick={() => handleOptionClick(value.value.toString(), value.text)}
                    key={index}
                  >
                    <div className={css.slDropdownItemCheckbox}>
                      <div>
                        {values.includes(value.value.toString()) && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        )}
                      </div>
                    </div>
                    <div className={css.slDropdownItemText}>{value.text}</div>
                  </button>
                );

              return null;
            })}
          </div>
        )}
      </div>
    </div>
  );
});

Select.displayName = 'Select';
Select.defaultProps = {
  options: [],
  selected: [],
  visibility: true,
  placeholder: 'Нажмите для выбора'
};

export default Select;